import React from 'react'
import { Routes, Route, Link, useNavigate } from "react-router-dom";
export default function Footer() {
  return (
    <>
    
     <div className='footer'>2022 © Copyrights Nataraj Travels Hospitality<br></br><br></br><span><b><Link to="/terms-conditions" className='alinks'>Terms and Conditions</Link></b> | <b><Link to="/privacy-policy" className='alinks'>Privacy Policy</Link></b> | <b><Link to="/refund-policy" className='alinks'>Refund Policy</Link></b> | <b><Link to="/shipping" className='alinks'>Shipping and Delivery</Link></b></span> </div>
    
    </>
   
  )
}
