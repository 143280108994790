import React from 'react'
import { useState, useEffect } from "react";
import { db } from "../../DB/Firebase.js";
import { doc, deleteDoc,collection, query, onSnapshot } from "firebase/firestore";
import { Button, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";



export default function BusDetails() {

    //Data
    const [rows, setRows] = useState([]);
    const getBooks = () => {
        const q = query(collection(db, "bus_details"));
        onSnapshot(q, (querySnapshot) => {
            const rows = [];
            querySnapshot.forEach((doc) => {
                rows.push(doc.data())
            });
            setRows(rows);
        });
    };

    const deleteBook = async (busID) =>{
        await deleteDoc(doc(db, "bus_details", busID));
        alert("Bus data has been successfully deleted.")
      }


    useEffect(() => {
        getBooks();
    }, []);
    return (
        <>
        <div className='bus-data-table'>
        <TableContainer component={Paper}>
                <Table sx={{ minWidth: 550 }} >
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Sr.No.</b></TableCell>
                            <TableCell><b>Bus Name</b></TableCell>
                            <TableCell><b>Bus Name Plate</b></TableCell>
                            <TableCell><b>Bus Route</b></TableCell>
                            <TableCell><b>Bus Date</b></TableCell>
                            <TableCell><b>AC</b></TableCell>
                            <TableCell><b>Trip Fare</b></TableCell>
                            <TableCell><b>Total Seats</b></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                {index + 1}
                                </TableCell>
                                <TableCell>{row.busName}</TableCell>
                                <TableCell>{row.busNamePlate}</TableCell>
                                <TableCell>{row.busRoute}</TableCell>
                                <TableCell>{row.busDate}</TableCell>
                                <TableCell>{row.busAC}</TableCell>
                                <TableCell><b>₹ {row.busFare}</b></TableCell>
                                <TableCell><b>{row.busCapacity}</b></TableCell>
                                <TableCell>
                                    <Button variant="contained" color="error" onClick={()=>deleteBook(row.busID)} >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


        </div>
            
        </>
    )
}
