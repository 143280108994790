import React, { createContext, useContext, useEffect,useState } from 'react'
import { auth } from '../DB/Firebase'
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    onAuthStateChanged,
    signOut,
} from 'firebase/auth'


const DataContext = createContext({
    getCurrentUserData: () => Promise,
    currentUser: null,
    login: () => Promise,
    register: () => Promise,
    logout: () => Promise,
    forgotPassword: () => Promise,
  

})

export const useDataAuth = () => useContext(DataContext)

export default function AuthContextProvider({ children }) {


    const [currentUser, setCurrentUser] = useState(null)
    const [singleUser, setSingleUser] = useState([])

    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, user => {
            setCurrentUser(user ? user : null)
            
        })

        return () => {
            unsubscribe()
        }
    }, [])

    function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password)
    }

    function register(email, password) {
        return createUserWithEmailAndPassword(auth, email, password)
    }

    function forgotPassword(email) {
        return sendPasswordResetEmail(auth, email, {
            url: `http://localhost:3000/login`,
        })
    }

    function logout() {
        return signOut(auth)
    }
    function getCurrentUserData(userOBJ) {
        return userOBJ
    }



    const value = {
        getCurrentUserData,
        currentUser,
        login,
        register,
        logout,
        forgotPassword,

    }
    return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}
