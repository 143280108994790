import React from 'react'

export default function Termsnconditions() {
    return (
        <>
            <div className='container-main'>

                <div className='terms-container'>            <h3>Terms and Conditions</h3>
                    <p>
                        Use of the website is subject to the terms and conditions. We kindly ask that you carefully read our terms and conditions in order to comprehend our website and its unique features, such as our privacy policy and introduction.<br></br><br></br>

                        As a member, you are expected to abide by all the terms of the membership agreement. Both the desktop and mobile versions of the Nataraj Travels Hospitality website and app are available. You are provided with the service on the condition that you accept and agree to the terms of this agreement, which are owned and operated by Nataraj Travels Hospitality. Members accept all things in the terms of use and agree to abide by and are legally bound by them by checking the "I have read and accept the terms of use" box during the registration procedure.<br></br><br></br>

                        Those guidelines represent one type of agreement between you and Nataraj Travels Hospitality. It is assumed that you are ready to abide by the terms after visiting the Nataraj Travels Hospitality website and accepting them. If you do not agree to the terms and conditions, you are not permitted to use the website.<br></br><br></br>

                    </p>

                    <p>
                        Nataraj Travels Hospitality has the absolute discretion to alter the same with or without notifying you.<br></br><br></br>

                        The modifications are repeatable to view. We will assume that you accept all the terms if you use our website.<br></br>


                    </p>

                </div>

            </div>


        </>
    )
}
