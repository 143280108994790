import React from 'react'

export default function RefundPolicy() {
    return (
        <>
           <div className='container-main'>
            <div className='terms-container'>
                <h3>Refund of Deposit:</h3>
                <p>
                    We will check all of your outstanding debts before returning your money. You will get your refund after 14days of cancelling your membership. You will receive your deposit back using the following methods: Gpay, Cheque, Phonepay, RTGS, and Account Transfer.<br></br><br></br>

                    Contact us for any other enquiry: +91 808 0429 020 | +91 908 2511 105 or <br></br><br></br>
                    Email us on : info@natarajhospitality.com

                </p>
            </div>
            </div>
        </>
    )
}
