import React from 'react'

import { Routes, Route, Link, useNavigate } from "react-router-dom";
import logo2 from "../media/logo2.png"

export default function About() {

    return (
        <>
         <div className="hero-home">
                <div className="hero-content-home">
                    <div className="hero-home-title shadoww">
                        Dekho Mumbai<br></br> is a joyful ride<br></br> in Mumbai.
                    </div>
                    <div className="hero-home-subTile shadoww">
                        Mumbai is all of dreams, passion and freedom.<br></br> Each moment in Mumbai is full of life and excitments,<br></br> lets explore Mumbai in most unique way.

                    </div>
                    <div className='learnmore-btn'><Link to="/">Explore</Link></div>

                </div>

            </div>
            <div className='container-main'>
             {/* <h3 className='tSpace h3-bg page-heading'><img src={bus2} alt="bus" style={{ maxWidth: "50px", position: "absolute", marginLeft: "-52px", marginTop: "-10px" }} />About Nataraj Travels Hospitality</h3>*/}   
             <h3 className='tSpace h3-bg page-heading' style={{marginTop: "0px"}}><p style={{fontSize:"16px", top:"-8px"}}>About Nataraj Travels Hospitality</p></h3>
                <br></br> <p >We follow the COVID-19 norms and all our buses are cleaned, sanitized after each trip. Time to time santize the seats.</p>

                <div className='mdContent-container'>
                        <img src={logo2} alt="Logo" className='mdImg' />
                        <p className='mdContent'>
                           We are here since decades and believe in quality and satisfaction to all our customers. We know what our customers needs and we strieve hard to achieve it. On these terms we came up with a concept of Dekho Mumbai. We as a team are here to make your tour in Mumbai one of the best. 
                       
                        </p>
                        
                    </div>


            </div>

        </>
    )
}
