import { Paper } from '@mui/material';
import React from 'react'

import "./CustomerPage.css"

export default function PaymentFailed() {

    return (
        <>
            <Paper style={{ width: "60%", height: "200px", margin: "30px auto" }} className='flex-css'>
                <div >
                    <h2><b>Payment Failed! Please check your Internet connection or check with your Bank!</b></h2><br>
                    </br>
                    <h4>Thank you!</h4>
                </div>

            </Paper>
        </>
    )
}
