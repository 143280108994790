import React, { useState } from 'react'
import "./CustomerPage.css"
import { useNavigate, useLocation } from "react-router-dom";
import { FaPlusCircle } from "react-icons/fa";
import { Button } from '@mui/material';
import { Link } from "react-router-dom";




export default function CustomerPage(props) {
    let price = 0;
    const location = useLocation();
    const navigate = useNavigate();
    const date = location.state.dta.busDate;


    const [year, month, day] = date.split('-');

    const result = [day, month, year].join('-');

    const [seatSelected, setSeatSelected] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [packageAmount, setPackageAmount] = useState(null);


    const [seatBooked, setSeatBooked] = useState([])

    const handlSeatClick = (id) => {

        //setTotalAmount(location.state.dta.busFare*setSeatSelected.length)
        let seatColorDiv = document.getElementById(id)

        var idx = seatSelected.indexOf(id);
        if (idx >= 0) {
            seatSelected.splice(idx, 1);
            seatColorDiv.style.backgroundColor = "#FFA500"
            seatColorDiv.style.color = "black"
            seatColorDiv.style.fontWeight = "bold"
            setTotalAmount(totalAmount - Number(location.state.dta.busFare))
        } else {
            setSeatSelected([...seatSelected, id]);
            seatColorDiv.style.backgroundColor = "#9e6b0d"
            seatColorDiv.style.color = "white"
            seatColorDiv.style.fontWeight = "bold"
            seatSelected.sort()
            setTotalAmount(Number(location.state.dta.busFare) * Number(seatSelected.length + 1))
        }
        
    }

    const handlePackage = (e) => {



        price = totalAmount + Number(e.target.innerHTML);
        setTotalAmount(price)
    }

    const handleToPayment = (dataa, sData, tTotal) => {
        navigate("/payment-confirmation", { state: { dta: dataa, userSeats: sData, totalAmt: tTotal } });

    }

    const createSeats = () => {
        const row = [];
        for (var i = 0; i < 41; i++) {
            row.push(<li key={i} id={i} onClick={(e) => { handlSeatClick(e.target.id) }}>{i}</li>);

        }

        return row;
    };

    return (
        <>
            <section id="bus-package-section">
                <h3>Bus information</h3>
                <div className='bus-package-container'>

                    <span className='info1'><b>Route: <span style={{ color: "#0895d3", fontSize: "20px" }}>{location.state.dta.busRoute}</span></b> <b> /  {location.state.dta.busAC === "Yes" ? "AC Bus" : "Non AC Bus"} / </b>  <b><span style={{ color: "#0895d3", fontSize: "20px" }}>{result}</span></b></span>
                    <span className='info1'><b>Fare : ₹ <span style={{ color: "#0895d3", fontSize: "20px" }}>{location.state.dta.busFare}</span></b> <b> / {location.state.dta.busCapacity} seats available</b></span>
                    <span className='info1'><b>Arrival : <span style={{ color: "#0895d3", fontSize: "18px" }}>{location.state.dta.busArrival}</span></b> <b> / Departure :<span style={{ color: "#0895d3", fontSize: "18px" }}> {location.state.dta.busDeparture}</span></b></span>
                </div>
            </section>

            <section className='bus-layout-container'>

                <div className='bus-layout'>
                    <small style={{ paddingBottom: "10px", fontSize: "16px" }}><b>{location.state.dta.busNamePlate}</b></small>
                    <div className='bus-seats'>
                        <ul style={{ display: "inline-block" }}>
                            <li id={1} onClick={(e) => { handlSeatClick(e.target.id) }}>1</li>
                            <li id={5} onClick={(e) => { handlSeatClick(e.target.id) }}>5</li>
                            <li id={9} onClick={(e) => { handlSeatClick(e.target.id) }}>9</li>
                            <li id={13} onClick={(e) => { handlSeatClick(e.target.id) }}>13</li>
                            <li id={17} onClick={(e) => { handlSeatClick(e.target.id) }}>17</li>
                            <li id={21} onClick={(e) => { handlSeatClick(e.target.id) }}>21</li>
                            <li id={25} onClick={(e) => { handlSeatClick(e.target.id) }}>25</li>
                            <li id={29} onClick={(e) => { handlSeatClick(e.target.id) }}>29</li>
                            <li id={33} onClick={(e) => { handlSeatClick(e.target.id) }}>33</li>
                            <li id={37} onClick={(e) => { handlSeatClick(e.target.id) }}>37</li>

                        </ul>
                        <ul style={{ display: "inline-block" }}>
                            <li id={2} onClick={(e) => { handlSeatClick(e.target.id) }}>2</li>
                            <li id={6} onClick={(e) => { handlSeatClick(e.target.id) }}>6</li>
                            <li id={10} onClick={(e) => { handlSeatClick(e.target.id) }}>10</li>
                            <li id={14} onClick={(e) => { handlSeatClick(e.target.id) }}>14</li>
                            <li id={18} onClick={(e) => { handlSeatClick(e.target.id) }}>18</li>
                            <li id={22} onClick={(e) => { handlSeatClick(e.target.id) }}>22</li>
                            <li id={26} onClick={(e) => { handlSeatClick(e.target.id) }}>26</li>
                            <li id={30} onClick={(e) => { handlSeatClick(e.target.id) }}>30</li>
                            <li id={34} onClick={(e) => { handlSeatClick(e.target.id) }}>34</li>
                            <li id={38} onClick={(e) => { handlSeatClick(e.target.id) }}>38</li>
                        </ul>
                        <ul style={{ display: "inline-block" }}>
                            <li className='no-seat'></li>
                            <li className='no-seat'></li>
                            <li className='no-seat'></li>
                            <li className='no-seat'></li>
                            <li className='no-seat'></li>
                            <li className='no-seat'></li>
                            <li className='no-seat'></li>
                            <li className='no-seat'></li>
                            <li className='no-seat'></li>
                            <li id={39} onClick={(e) => { handlSeatClick(e.target.id) }}>39</li>
                        </ul>

                        <ul style={{ display: "inline-block" }}>
                            <li id={3} onClick={(e) => { handlSeatClick(e.target.id) }}>3</li>
                            <li id={7} onClick={(e) => { handlSeatClick(e.target.id) }}>7</li>
                            <li id={11} onClick={(e) => { handlSeatClick(e.target.id) }}>11</li>
                            <li id={15} onClick={(e) => { handlSeatClick(e.target.id) }}>15</li>
                            <li id={19} onClick={(e) => { handlSeatClick(e.target.id) }}>19</li>
                            <li id={23} onClick={(e) => { handlSeatClick(e.target.id) }}>23</li>
                            <li id={27} onClick={(e) => { handlSeatClick(e.target.id) }}>27</li>
                            <li id={31} onClick={(e) => { handlSeatClick(e.target.id) }}>31</li>
                            <li id={35} onClick={(e) => { handlSeatClick(e.target.id) }}>35</li>
                            <li id={40} onClick={(e) => { handlSeatClick(e.target.id) }}>40</li>
                        </ul>
                        <ul style={{ display: "inline-block" }}>
                            <li id={4} onClick={(e) => { handlSeatClick(e.target.id) }}>4</li>
                            <li id={8} onClick={(e) => { handlSeatClick(e.target.id) }}>8</li>
                            <li id={12} onClick={(e) => { handlSeatClick(e.target.id) }}>12</li>
                            <li id={16} onClick={(e) => { handlSeatClick(e.target.id) }}>16</li>
                            <li id={20} onClick={(e) => { handlSeatClick(e.target.id) }}>20</li>
                            <li id={24} onClick={(e) => { handlSeatClick(e.target.id) }}>24</li>
                            <li id={28} onClick={(e) => { handlSeatClick(e.target.id) }}>28</li>
                            <li id={32} onClick={(e) => { handlSeatClick(e.target.id) }}>32</li>
                            <li id={36} onClick={(e) => { handlSeatClick(e.target.id) }}>36</li>
                            <li id={41} onClick={(e) => { handlSeatClick(e.target.id) }}>41</li>
                        </ul>

                    </div>
                    <small className='seat-legend'><span>Seats Selected</span>  <br></br>{seatSelected.join(", ")}</small><br></br><br></br>

                </div>

            </section>



            <section className='package-info-container'>

                <div className='package-heading'>

                    <div className='package-list'>
                        <div className='h3-heading'>Mumbai darshan Packages</div>
                        <ul>
                            <li>Boating Club <FaPlusCircle /><span className='package-fare' value="80" onClick={(e) => { handlePackage(e) }} > 80</span></li>
                            <li>Sangrahalaya <FaPlusCircle /><span className='package-fare' value="100" onClick={(e) => { handlePackage(e) }}> 100</span></li>
                            <li>Orama 4D Magic Show <FaPlusCircle /><span className='package-fare' value="220" onClick={(e) => { handlePackage(e) }} > 220</span></li>
                        </ul>
                        <p>Add the above packages to save time when you are on Mumbai Darshan tour. This will avoid long queues.</p>
                    </div>
                </div>

            </section>

            <div className='totalAmount-div-container' >
                <div className='totalAmount-div'> <h4>Total Amount :</h4><h3>₹ {totalAmount}</h3><small>( Taxes will be calculated during payment )</small>
                    <br></br> <br></br>
                    <Button variant="outlined" onClick={(e) => { handleToPayment(location.state.dta, seatSelected, totalAmount) }} >Continue</Button></div>

            </div>
            <div>

            </div>

        </>
    )
}
