import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { db } from "../../DB/Firebase.js";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

export default function AddBus() {
    const navigate = useNavigate();
    const [busAC, setBusAC] = useState("");
    const [busArrival, setBusArrival] = useState("");
    const [busCapacity, setBusCapacity] = useState(undefined);
    const [busDate, setBusDate] = useState(undefined);
    const [busDeparture, setBusDeparture] = useState("");
    const [busFare, setBusFare] = useState(undefined);
    const [busName, setBusName] = useState("");
    const [busNamePlate, setBusNamePlate] = useState("");
    const [busRoute, setBusRoute] = useState("");



    const addBusDetails = async () => {



        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        function generateString(length) {
            let result = '';
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }

            return result;
        }

        let busID = generateString(12);
        if (busAC !== "" && busArrival !== "" && busCapacity !== "" && busDate !== "" && busDeparture !== "" && busFare !== "" && busName !== "" && busNamePlate !== "" && busRoute !== "") {
            try {
                //add Bus data to collection called Bus Details
                await setDoc(doc(db, "bus_details", busID), {
                    busID,
                    busAC,
                    busArrival,
                    busCapacity,
                    busDate,
                    busDeparture,
                    busFare,
                    busName,
                    busNamePlate,
                    busRoute
                });
                //clear text fields
                setBusAC("");
                setBusArrival("");
                setBusCapacity("");
                setBusDate("");
                setBusDeparture("");
                setBusFare("");
                setBusName("");
                setBusNamePlate("");
                setBusRoute("");

                alert("A new bus details has been added to the Database!");
                navigate("/busDetails");
            } catch (e) {
                console.error("Error adding document: ", e);
            }
        } else alert("Please fill all the Missing fields");
    };





    return (

        <>
            <div className="form-Container">
                <br></br>
                <h3 className='tSpace h3-bg page-heading' style={{marginTop: "-20px"}}>Add Bus Data</h3>
         
                <br></br>

                <div className="form-align">


                    <TextField
                        label="Bus Name"
                        placeholder="Bus Name"
                        value={busName}
                        onChange={(e) => {
                            setBusName(e.target.value);
                        }}
                    />
                    <TextField
                        label="Bus Name Plate"
                        placeholder="Bus Name Plate"
                        value={busNamePlate}
                        onChange={(e) => {
                            setBusNamePlate(e.target.value);
                        }}
                    />
                    <TextField
                        label="Bus Fare"
                        placeholder="Bus Fare"
                        value={busFare}
                        type="number"
                        onChange={(e) => {
                            setBusFare(e.target.value);
                        }}
                    />

                    <TextField
                        label="Bus Route"
                        placeholder="Bus Route"
                        value={busRoute}

                        onChange={(e) => {
                            setBusRoute(e.target.value);
                        }}
                    />
                    <TextField
                        label="Bus Capacity"
                        placeholder="Bus Capacity"
                        value={busCapacity}
                        type="number"
                        onChange={(e) => {
                            setBusCapacity(e.target.value);
                        }}
                    />

                </div>



                <div className="form-align">
                    <TextField
                        label="Bus AC"
                        placeholder="Bus AC"
                        value={busAC}

                        onChange={(e) => {
                            setBusAC(e.target.value);
                        }}
                    />

                    <TextField
                        label="Bus Arrival"
                        placeholder="Bus Arrival"
                        value={busArrival}

                        onChange={(e) => {
                            setBusArrival(e.target.value);
                        }}
                    />


                    <TextField
                        label="Bus Departure"
                        placeholder="Bus Departure"
                        value={busDeparture}

                        onChange={(e) => {
                            setBusDeparture(e.target.value);
                        }}
                    />

                    <TextField

                        placeholder="Bus Date"
                        value={busDate}
                        type="date"
                        onChange={(e) => {
                            setBusDate(e.target.value);
                        }}
                    />

                </div>

                <br></br>
                <div className="form-align">
                    <Button variant="contained" onClick={addBusDetails}>Save Bus Details</Button>
                </div>



            </div>

        </>

    )
}
