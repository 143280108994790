import React from 'react'

export default function PrivacyPolicy() {
    return (
        <>
         <div className='container-main'>
            <div className='terms-container'>
                <h3>Refund of Deposit</h3>
                <p>
                    The visitors' privacy is a top priority at Nataraj Travels Hospitality, which is reachable at https://natarajhospitality.com/.
                    • Before deciding to use our services, make sure you have read our privacy statement thoroughly to get familiar with all of our policies. You will discover more about the ways in which we use the information you supply.
                    • Those rules and regulations change on a regular basis as a result of updating, therefore it's important to be informed.
                    • We require your home address, contact information, and email address. We use your data for your best user experience and send you mail, or get in touch with you about updates. You can call us or send us an email if you need more information.
                    • We'll keep you up to date on new offers, releases and mail-order specials regularly.
                    


                </p>
                <h3>Consent</h3>
                <p>
                    You hereby accept and agree to the terms of our Privacy Policy and the use of our website.
                    Data that we gather

                    When we ask you to provide personal information, we will make sure to explain what that information entails as well as why we're asking you for it.
                    In the event that you get in touch with us directly, we may also learn more about you, including your name, contact information (phone, email, message, and/or attachments), and any other details you may choose to share.

                    How do we use your information?
                    We make use of the data we gather in a variety of ways, such as:
                    •    Provide, run and maintain our website
                    •    Enhance, customize, and broaden our website
                    •    Recognize and examine how you use our website.
                    •    Create fresh products, services, features, and functionalities.
                    •    Communicate with you, either directly or through one of our partners, for customer service, to give you website updates and other information, and for marketing and promotional purposes.
                    •    Send you emails.
                    •    Detect and stop fraud.


                </p>
                <h3>Log files
                </h3>
                <p>
                    Nataraj Travels Hospitality utilizes log files in accordance with industry standards. These files keep track of who visits which websites. As part of their hosting services' analytics, all hosting firms carry out this action. Internet protocol (IP) addresses, browser type, Internet service provider (ISP), date and time stamps referring/exit pages, and maybe click counts are among the data that log files collect. These are not connected to any personally identifying data. The information is used for trend analysis, website administration, user behavior tracking, and demographic data collection.

                </p>
                <h3>Cookies Policy

                </h3>
                <p>
                    Nataraj Travels Hospitality utilizes "cookies'' just like any other website. These cookies are used to store data about visitors' preferences and the web pages they access or browse. By tailoring the content of our web pages to visitors' browser types and/or other information, we can improve the users' experience.
                    Privacy Policies for Advertising Partners
                    This list of Nataraj Travels Hospitality's advertising partners' privacy policies is available for your reference.
                    Please be aware that Nataraj Travels Hospitality is unable to access or manage any of the third-party cookies that are utilized by advertising.
                    The privacy practices of third parties

                    Other advertisers or websites are not covered by Nataraj Travels Hospitality's Privacy Policy. As a result, we suggest that you review the individual Privacy Policies of these third-party ad servers for more information. It might also contain information on their policies and how to reject particular choices.
                    Through the choices available in each browser, you can decide to turn off cookies. On the websites for each online browser, you may find more in-depth information about managing cookies with that particular browser.
                    Information for Children

                    Adding security for kids using the internet is another one of our top priorities. We urge parents and guardians to keep an eye on, engage in, monitor, and/or direct their children's internet behavior.
                    Children under the age of 13 are not willingly provided with any Personal Identifiable Information by Nataraj Travels Hospitality. We firmly advise you to notify us immediately if you believe that your child provided this kind of information on our website. We will make every attempt to rapidly erase such information from our database.


                </p>
                <h3>Disclosure


                </h3>
                <p>
                    • We never divulge your information without first notifying you.
                    • In order to streamline our business operations, we occasionally engage with outside parties such as agents, contractors, and businesses for bank transactions, deliveries, and advertisements, therefore we may need to share your address. You can establish personal connections with such mediators when communicating with them, but if any problems resulting from those connections arise in the future, we disclaim all liability.

                </p>
                <h3>Customer Safety



                </h3>
                <p>
                We employ a secured server to store your data when you enter it, preventing its malfunction and disclosure.

                </p>
                <h3>Cancellation policy




                </h3>
                <p>
                The ticket fare will either be given to you through cheque or transferred to your account within 14 days of canceling your enrollment.


                </p>
            </div>
            </div>
        </>
    )
}
