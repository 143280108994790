import React from 'react'

export default function Shipping() {
    return (
        <>
           <div className='container-main'>
            <div className='terms-container'>
                <h3>Shipping and Delivery:</h3>
                <p>
                    Shipping is not required as the delivery is the booking of the Bus seat. It's an instant delivery to the customer.<br></br><br></br>

                    Contact us for any other enquiry: +91 808 0429 020 | +91 908 2511 105 or <br></br><br></br>
                    Email us on : info@natarajhospitality.com

                </p>
            </div>
            </div>
        </>
    )
}
