import React from 'react'
import { Routes, Route, Link, useNavigate } from "react-router-dom";

import packImg from "../media/packages.jpg"

export default function Packages() {

    return (
        <>
         <div className="hero-home">
                <div className="hero-content-home">
                    <div className="hero-home-title shadoww">
                        Dekho Mumbai<br></br> is a joyful ride<br></br> in Mumbai.
                    </div>
                    <div className="hero-home-subTile shadoww">
                        Mumbai is all of dreams, passion and freedom.<br></br> Each moment in Mumbai is full of life and excitments,<br></br> lets explore Mumbai in most unique way.

                    </div>
                    <div className='learnmore-btn'><Link to="/">Explore</Link></div>

                </div>

            </div>
            <div className='container-main'>
                <h3 className='tSpace h3-bg page-heading' style={{marginTop: "0px"}} ><p style={{fontSize:"16px", top:"-12px"}}>Everything you want to know about Mumbai Darshan by Dekho Mumbai</p></h3>
                <br></br> <p >We follow the COVID-19 norms and all our buses are cleaned, sanitized after each trip. Time to time santize the seats.</p>


                <div>
                    <div className='mdContent-container'>
                        <img src={packImg} alt="Mumbai Darshan" className='mdImg' />
                        <p className='mdContent'>
                            We cover the Mumbai Darshan Points along with the packages. Every place in Mumbai has some speciality and to enjoy it we should be stress and tension free. To do that we came up with packages that you can choose while booking your Mumbai Darshan tour with us.
                        </p>

                    </div>
                    <div style={{ marginTop: "50px" }}>
                        <h4>Place we cover in the Mumbai Darshan tour:</h4>
                        <div className='darshan-points'>

                            <span>- Gateway of India</span>
                            <span>- Siddhivinayak Temple</span>
                            <span>- Bandra Bandstand</span>
                            <span>- Mani Bhavan</span>
                            <span>- Hanging Garden</span>
                            <span>- Taj Mahal Hotel</span>
                            <span>- Nehru Science Center</span>
                            <span>- Nehru Center</span>
                            <span>- Girgaum Chowpatty</span>
                            <span>- Taraporewala Aquarium</span>
                            <span>- Mahalaxmi Race Course</span>
                            <span>- Dr. Bhau Daji Lad Museum Dhobi Ghat</span>
                            <span>- Bandra Worli Sea Link</span>
                            <span>- Chhatrapati Shivaji Vastu Sangrahalaya</span>

                        </div>
                    </div>

                </div>


            </div>

        </>
    )
}
