import React from 'react';

import './App.css';
import logo from './media/logo.png'
import Button from '@mui/material/Button';
import { Routes, Route, Link, useNavigate } from "react-router-dom";


import Footer from './Components/Footer';
import MumbaiDarshan from './Components/MumbaiDarshan';
import Packages from './Components/Packages';
import About from './Components/About';
import Contact from './Components/Contact';
import AddBus from './Components/Admin/AddBus';
import BusDetails from './Components/Admin/BusDetails';
import CustomerPage from './Components/CustomerPage';
import BusRoute from './Components/BusRoute';
import PaymentPage from './Components/PaymentPage';
import ConfirmPay from './Components/ConfirmPay';
import Termsnconditions from './Components/Termsnconditions';
import PrivacyPolicy from './Components/PrivacyPolicy';
import RefundPolicy from './Components/RefundPolicy';
import PaymentFailed from './Components/PaymentFailed';
import Shipping from './Components/Shipping';
import { useDataAuth } from './Context/DataContext';


function App() {
  const navigate = useNavigate();
  const [busRoute, setBusRoute] = React.useState('');
  const [showRouteMsg, setShowRouteMsg] = React.useState(false);
  const { currentUser, logout } = useDataAuth()

  
  const handleBusRoute = (busRoutes) => {

    switch (busRoutes) {
      case "Virar":
        setBusRoute(busRoutes);
        setTimeout(() => {
          navigate("/bus-route", { state: { bRoute: busRoutes } });
        }, 2200);

        break;
      case "Panvel":
        setBusRoute(busRoutes);
        setTimeout(() => {
          navigate("/bus-route", { state: { bRoute: busRoutes } });
        }, 2200);
        break;
      case "VT":
        setBusRoute(busRoutes);
        setTimeout(() => {
          navigate("/bus-route", { state: { bRoute: busRoutes } });
        }, 2200);
        break;

      default:
        break;
    }
    setShowRouteMsg(true)

  }


  return (

    <div className='container-main'>
      <div className='contact-block'>Contact us for Mumbai Darshan Bookings : + 919967429020 | Many packages to select from while booking the tickets. </div>
      <div className='menu-block'>

        <div className='menu-items'>
          <div className='logo-block'><img src={logo} alt="Logo" title="Logo" width="160px" /></div>
          <ul>
            <li><Link to="/" className='alinks'>Home</Link></li>
            <li><Link to="/mumbai-darshan" className='alinks'>Mumbai Darshan</Link></li>
            <li><Link to="/packages" className='alinks'>Packages</Link></li>
            <li><Link to="/about-us" className='alinks'>About</Link></li>
            <li><Link to="/contact-us" className='alinks'>Contact</Link></li>
            {currentUser && <li>{currentUser !== "null" ? <span onClick={async e => {
              e.preventDefault()
              await logout()
              navigate('/')
            }}>Logout</span> : ""}</li>}

            {/* <div className='admin-menu'>
              <ul>

                <li><Link to="/admin-panel" className='alinks'>Admin</Link></li>
                <li><Link to="/agent-panel" className='alinks'>Agent</Link></li>
              </ul>

            </div>*/}

          </ul>
        </div>


      </div>



      <Routes basename={'/'}>
        <Route path="/" element={<div className='form-home'>
          <div className="hero-home-2">
            <div className="hero-home-title shadoww">
              <h5>Dekho Mumbai is a joyful ride in Mumbai.</h5>
            </div>


          </div>
          <h3 className='tSpace h3-bg page-heading' style={{ marginTop: "5px" }}><p style={{ fontSize: "22px", top: "-12px" }}>Mumbai Darshan Trips</p></h3>
          <p>Select the desired route for an amazing Mumbai Darshan. Know Mumbai closely.</p>
          <div className="place-btn">

            <div className='ui-button' onClick={() => handleBusRoute("Virar")} name="v1">Virar - Western</div>
            <div className='ui-button' onClick={() => handleBusRoute("Panvel")} name="p1">Panvel - Central</div>
            <div className='ui-button' onClick={() => handleBusRoute("VT")} name="vt">VT- South Mumbai</div>


          </div>
          <div>..........................................................................</div>
          {showRouteMsg && <div style={{ fontWeight: "600", color: "black" }}>You selected <h3>{busRoute}</h3> we will fetch the desired information<br></br>as per your selection</div>}

        </div>} />
        <Route path="bus-route" element={<BusRoute />} />

        <Route path="mumbai-darshan" element={<MumbaiDarshan />} />
        <Route path="packages" element={<Packages />} />
        <Route path="about-us" element={<About />} />
        <Route path="contact-us" element={<Contact />} />
        {/* <Route path="add-bus-details" element={<AddBus />} /> */}
        <Route path="busDetails" element={<BusDetails />} />
        <Route path="admin-panel" element={<AddBus />} />
        <Route path="customer-page" element={<CustomerPage />} />
        <Route path="payment-confirmation" element={<PaymentPage />} />
        <Route path="confirm-payment-details" element={<ConfirmPay />} />
        <Route path="payment-failed" element={<PaymentFailed />} />
        <Route path="terms-conditions" element={<Termsnconditions />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="refund-policy" element={<RefundPolicy />} />
        <Route path="shipping" element={<Shipping />} />
      </Routes>

      <Footer />

    </div>
  );
}

export default App;
