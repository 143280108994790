import React from 'react'
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import mdImg from "../media/md.jpg"

export default function MumbaiDarshan() {

    return (
        <>
            <div className="hero-home">
                <div className="hero-content-home">
                    <div className="hero-home-title shadoww">
                        Dekho Mumbai<br></br> is a joyful ride<br></br> in Mumbai.
                    </div>
                    <div className="hero-home-subTile shadoww">
                        Mumbai is all of dreams, passion and freedom.<br></br> Each moment in Mumbai is full of life and excitments,<br></br> lets explore Mumbai in most unique way.

                    </div>
                    <div className='learnmore-btn'><Link to="/">Explore</Link></div>

                </div>

            </div>

            <div className='container-main'>
                <h3 className='tSpace h3-bg page-heading' style={{ marginTop: "0px" }}><p style={{ fontSize: "16px", top: "-12px" }}>Everything you want to know about Mumbai Darshan by Dekho Mumbai</p></h3>
                <br></br> <p >We follow the COVID-19 norms and all our buses are cleaned, sanitized after each trip. Time to time santize the seats.</p>


                <div>
                    <div className='mdContent-container'>
                        <img src={mdImg} alt="Mumbai Darshan" className='mdImg' />
                        <p className='mdContent'>
                            Mumbai Darshan is a one-day city tour covering about 60 tourist attractions of Mumbai. These include 14 sightseeing spots and rest are the drive-past spots. Mumbai Darshan tour takes place in air-conditioned buses, and there are professionally trained and licensed guides to introduce you to the mesmerizing history associated with these locations. These packages are affordable and a brilliant way to get acquainted with Mumbai city. <br></br> <br></br>Currently, this city tour is operated only on the weekends- Saturday and Sunday. Also, you can choose from the 2 itineraries MTDC offers for Mumbai Darshan depending on your pick up location. The itineraries only differ in the bus journey and starting pick-up point; the attractions are similar, and hence, you can choose among them according to your convenience.
                        </p>

                    </div>



                </div>


            </div>

        </>
    )
}
