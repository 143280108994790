import { Paper } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import logo from '../media/logo.png'
import firebase from 'firebase/compat/app';
import emailjs, { init } from "@emailjs/browser";
import "./CustomerPage.css"
import { db } from "../DB/Firebase.js";
import { doc, deleteDoc, where, collection, query, onSnapshot, QuerySnapshot, getDoc } from "firebase/firestore";
import "./ConfirmPay.css"



export default function ConfirmPay() {
    const [userData, setUserData] = useState([]);


    const location = useLocation();
    const navigate = useNavigate();
    const custID = location.state.custID;

    const userDBData = location.state.userDB

    /* const payData = location.state.payDetails;
     const userName = location.state.uname;
     const userEmail = location.state.uemail;
     const userLName = location.state.lname;
     const busAmountFare = location.state.busAmount;
     
     const userEmailData = location.state.emailData*/

    console.log(" userDBData : " + Object.values(userDBData))

    const [rows, setRows] = useState([]);
    const getUserData = async () => {
        const docRef = doc(db, "user_bookings", custID);

        try {
            const docSnap = await getDoc(docRef);
            //console.log(" DATATAA ID WISE : " + Object.values(docSnap.data()));
            setUserData(docSnap.data())
            
           
        } catch (error) {
            console.log(error)
        }

        /*const q = query(collection(db, "user_bookings", custID));
       onSnapshot(q, (querySnapshot) => {
           const rows = [];

           querySnapshot.forEach((doc) => {
             
               rows.push(doc.data())

           });
           setRows(rows);
           console.log("......"+rows[0])
       });

*/





    };


    // console.log(Object.keys(userData))

    useEffect(() => {

        getUserData()



        return () => {

        }
    }, [])




    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    /*let userParams = {
        acbus: userEmailData[0],
        busArrival: userEmailData[1],
        busDeparture: userEmailData[2],
        busDate: userEmailData[3],
        busRoute:userEmailData[4],
        busFare:userEmailData[5],
        busSeats:userEmailData[6],
       

    };*/

    //emailjs.send('service_kpayhuf', 'template_7n427as', userParams);



    /*       emailjs.send('service_kpayhuf', 'template_7n427as', userEmailData)
               .then(function (response) {
                   console.log('SUCCESS!', response.status, response.text);
               }, function (error) {
                   console.log('FAILED...', error);
               });*/




    //alert("Success!!")
    return (
        <>
            <Paper className='flex-css paper-css'>

                <div ref={componentRef} style={{ padding: "30px", lineHeight: "40px" }}>
                    <div><img src={logo} title="logo" alt="logo" style={{ maxWidth: "130px" }} /></div>

                    {userDBData.firstName !== "" ? <h3>Hi! {userData.firstName}, Please check your DekhoMumbai Ticket Details</h3> : <h3>Hi! Please check your DekhoMumbai Ticket Details</h3>}
                    {/* <h3>RazorPay Payment ID : {userDBData.razorresponse.razorpay_payment_id}</h3>*/}
                    <h4>User ID : {custID}</h4>
                    {userDBData.firstName !== "" ? <h4>User Full Name : {userData.firstName}&nbsp;{userData.lastName}</h4> : ""}
                    {userDBData.userEmail !== "" ? <h4>User Email : {userData.userEmail}</h4> : ""}

                    <h4>Tour Date : {userDBData.busDate}</h4>
                    <h4>Bus Seats Booked : {userDBData.busSeats.join(", ")}</h4>
                    <h4>Bus Route : {userDBData.busRoute}</h4>
                    <h4>Bus Departure Time : {userDBData.busDeparture}</h4>
                    <h4>Bus Arrival Time : {userDBData.busArrival}</h4>

                    {userData.userPickUp !== "" ? <h4>Pickup Point : {userData.userPickUp}</h4> : ""}



                    {userData.userDropPoint !== "" ? <h4>Drop Point : {userData.userDropPoint}</h4> : ""}

                    {/*   
                
                
                
                
                <h4>Name : <span style={{fontSize:"20px", color:"orangered"}}>{location.state.uname} {location.state.lname}</span></h4>
                <h4>Payment Confirmation ID : <span style={{fontSize:"20px", color:"orangered"}}>{payData.razorpay_payment_id}</span></h4>     
                <h4>Booking date : <span style={{fontSize:"20px", color:"orangered"}}>{userParams.busDate}</span></h4>
                <h4>Bus Departure : <span style={{fontSize:"20px", color:"orangered"}}>{userParams.busDeparture}</span> </h4>
                <h4>Bus Arrival : <span style={{fontSize:"20px", color:"orangered"}}>{userParams.busArrival}</span> </h4>
                
                <h4>Bus Route : <span style={{fontSize:"20px", color:"orangered"}}>{userParams.busRoute}</span> </h4>
                <h4>Seats Booked : <span style={{fontSize:"20px", color:"orangered"}}>{userParams.busSeats}</span></h4>    
                <h4>Total Bus Fare : <span style={{fontSize:"20px", color:"orangered"}}>₹ {busAmountFare}</span> </h4><br></br>  
                <h2>Thank you {userName} for your Booking with Dekho Mumbai!</h2> <br></br>
                
    */}


                    {/*
                    <h2>Hello, {rows[0].firstName} {rows[0].lastName}</h2><br></br>
                    <hr></hr>
                    <br></br>
                    <ul style={{ listStyle: "none" }}>
                        <li><b>Customer ID: <span className='userInfo highlight' > {custID}</span></b></li>
                        <li><b>Name: <span className='userInfo '>{rows[0].firstName} {rows[0].lastName}</span> </b> </li>
                        <li><b>Customer Email: <span className='userInfo'> {rows[0].userEmail}</span></b></li>
                        <li><b>Customer Phone Number: <span className='userInfo'> {rows[0].userPhone}</span></b></li>
                        <li><b>Bus Departure: <span className='userInfo highlight'> {rows[0].busDeparture}</span></b></li>
                        <li><b>Bus Arrival: <span className='userInfo highlight'> {rows[0].busArrival}</span></b></li>
                        <li><b>Bus Fare: <span className='userInfo'>{rows[0].busFare}</span></b></li>
                        <li><b>Bus Name: <span className='userInfo'> {rows[0].busName}</span></b></li>
                        <li><b>Bus No: <span className='userInfo'>{rows[0].busNamePlate}</span></b></li>
                        <li><b>Bus Route: <span className='userInfo highlight'> {rows[0].busRoute}</span></b></li>
                        <li><b>Pick Point: <span className='userInfo highlight'>{rows[0].pickPoint}</span></b></li>
                        <li><b>Drop Point: <span className='userInfo highlight'> {rows[0].dropPoint}</span></b></li>
                        <li><b>Bus Seats: <span className='userInfo highlight'> {rows[0].userSeats}</span></b></li>
                        <li><b>Amount Paid: <span className='userInfo'> {Number(rows[0].totalAmt)}</span></b></li>
                    </ul>

                    <h2>Hello, Your Customer ID  : {custID} <br></br>
                    Thank you for your Booking with Dekho Mumbai!</h2> <br></br>
                    
                    <h3>Here are the details below :</h3>
                    <ul style={{ listStyle: "none" }}>
                        <li><b>Customer ID: <span className='userInfo highlight' > {custID}</span></b></li>
                        
              
                      
                        <li><b>Bus Departure: <span className='userInfo highlight'> {rows[0].busDeparture}</span></b></li>
                        <li><b>Bus Arrival: <span className='userInfo highlight'> {rows[0].busArrival}</span></b></li>
                 
             
                        <li><b>Bus Route: <span className='userInfo highlight'> {rows[0].busRoute}</span></b></li>
                   
                        <li><b>Bus Seats: <span className='userInfo highlight'> {rows[0].busSeats}</span></b></li>
         
                    </ul>
                    */}



                    {/*
                    
                     <ul style={{ listStyle: "none" }}>
                        <li><b>Customer ID: <span className='userInfo highlight' > {custID}</span></b></li>



                        <li><b>Bus Departure: <span className='userInfo highlight'> {rows[0].busDeparture}</span></b></li>
                        <li><b>Bus Arrival: <span className='userInfo highlight'> {rows[0].busArrival}</span></b></li>


                        <li><b>Bus Route: <span className='userInfo highlight'> {rows[0].busRoute}</span></b></li>

                        <li><b>Bus Seats: <span className='userInfo highlight'> {rows[0].busSeats.join(" , ")}</span></b></li>

                    </ul>
                    
                    
                    
                    */}

                    <h3>We are happpy to assist you! Enjoy your Mumbai Darshan </h3><br></br>
                    <hr></hr>

                    <h4>Incase of anything call us on :<br></br> +91 8080429020 | +91 9082511105 </h4>
                    <small>St. George Hospital – Ground 01, Plot-235, Hussaini Building, DMello Road, Fort, Mumbai, Maharashtra 400001</small>
                </div>
                <span onClick={handlePrint} style={{ cursor: "pointer", color: "black", fontWeight: "bold", border: "2px black solid", padding: "10px", borderRadius: "10px" }}><b>Print your ticket</b></span>

            </Paper>









        </>
    )
}
