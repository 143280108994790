import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";

import { TextField, Button } from "@mui/material";
import { db } from "../DB/Firebase.js";
import { doc, setDoc, collection, query, onSnapshot, QuerySnapshot } from "firebase/firestore";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import { useDataAuth } from '../Context/DataContext.js';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../DB/Firebase.js';


export default function PaymentPage() {
  const Razorpay = useRazorpay();
 //const rKeyID = "rzp_test_TlXMR1jAYnzzDN";
 const rKeyID = "rzp_live_WphapzVbn4se3E";
  const { register } = useDataAuth()
  const location = useLocation();
  const navigate = useNavigate();
  const datee = location.state.dta.busDate;
  const useats = location.state.dta.userSeats;
  const [year, month, day] = datee.split('-');
  const result = [day, month, year].join('-');

  let objUserData = {}
  const [userPhone, setPhone] = useState("");
  const [userEmail, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userPickUp, setUserPickUp] = useState("")
  const [userDropPoint, setUserDropPoint] = useState("")
  const [currentUser, setCurrentUser] = useState(null)
  const [userPassword, setUserPassword] = useState("")

  const pickDropData = ["Dahisar East- Ravindra Hotel",
    "Dahisar East- Near Gokul Anand",
    "Borivali East- National Park Main Gate",
    "Borivali West- Gokul Hotel",
    "Kandivali East- Samtanagar Police Station",
    "Kandivali East- Central Bank if India (CBI)",
    "Malad East - Alka Vihar Hotel",
    "Andheri East - Bisleri Company",
    "Vileparle East - Sahara Star Hotel",
    "Santacruz West - Asha Parikh Hospital",
    "Santacruz East - Vakola Bridge",
    "Bandra West - Sidharth Hotel",
    "Bandra East - Kala Nagar Bus Stop",
    "Sion Circle Hanuman Hotel",
    "Mahim West - Mahim Church",
    "Dadar East - Gurudwara",
    "Parel - Bharat Mata Cinema",
    "Byculla RaniBaug",
    "CST - Opp CST Station Near Mcdonald's"]

  let userID = "";




  // console.log(" DTA : ..."+userDataArray)

  let userDataArray = {}

  const HandleSavePayment = (e) => {
    alert("Save to Database and then proceeding towards payment");
  }

  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  function generateString(length) {
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }


  userID = generateString(12);
  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user ? user : null)
  
    })

    return () => {
      unsubscribe()
    }
  }, [])
  const handlePayment = (e) => {
    e.preventDefault()
    objUserData = {
      firstName: firstName,
      lastName: lastName,
      userEmail: userEmail,
      userPhone: userPhone,
      userPickUp: userPickUp,
      userDropPoint: userDropPoint
    }





    //console.log(Object.keys(objUserData))
    //saveUserData(objUserData)

    /*register(userEmail, userPassword)
      .then(res => {
        // navigate('/profile')
        console.log("user Created: " + Object.values(res))

        objUserData = {
          // userID: userID,
          firstName: firstName,
          lastName: lastName,
          userEmail: userEmail,
          userPhone: userPhone,
          userPickUp: userPickUp,
          userDropPoint: userDropPoint
        }
        console.log(" Data to to saved :" + Object.values(objUserData))
      })*/
    createUserWithEmailAndPassword(auth, userEmail, userPassword)
      .then(res => {
        console.log("user Created: " + res.user.uid )
        
       
        objUserData.userID = res.user.uid;

        console.log(" Data to to saved after Adding the new UID  :" + Object.values(objUserData))

        handleRazorPayment(objUserData)
   
      })
      .catch(error => {
        console.log(error.message)

      })
      .finally(() => {

      })
  }



  const handleRazorPayment = useCallback((e) => {
    const options: RazorpayOptions = {
      key: rKeyID,
      amount: location.state.totalAmt * 100,
      currency: "INR",
      name: "NATARAJ TRAVELS HOSPITALITY",
      description: "Payment for the seat booking for Mumbai Darshan",
      image: "https://dekhomumbai.com/preview2/static/media/logo2.7a31c2833049e07933ba.png",

      handler: (res) => {
        try {
          if (res) {

            console.log(" e values : "+Object.values(e) + " e Keys " + Object.keys(e) )
            saveUserData(e)



          }



          //console.log(res);
        } catch (error) {
          navigate("/payment-failed");
        }
      },
      prefill: {
        name: firstName,
        email: userEmail,
        contact: userPhone,
      },

      notes: {
        address: "St. George Hospital - Ground 01, Plot-235 Hussaini Building, DMello Road, Fort, Mumbai, Maharashtra 400001",
      },
      theme: {
        color: "#3399cc",
      },
    };




    const rzpay = new Razorpay(options);
    rzpay.open();
  }, [Razorpay]);

  /* const handlePayment = useCallback((e) => {
     e.preventDefault()
     objUserData = {
       userID: userID,
       firstName: firstName,
       lastName: lastName,
       userEmail: userEmail,
       userPhone: userPhone,
       userPickUp: userPickUp,
       userDropPoint: userDropPoint
     }
     console.log(" objUserData > " + Object.values(objUserData))
     localStorage.setItem("firstName", firstName)
     localStorage.setItem("lastName", lastName)
     localStorage.setItem("userEmail", userEmail)
     localStorage.setItem("userPhone", userPhone)
     localStorage.setItem("userPickUp", userPickUp)
     localStorage.setItem("userDropPoint", userDropPoint)
   
   
   
     let userFirstName = localStorage.getItem("firstName")
     let userlastName = localStorage.getItem("lastName")
     let userUserEmail = localStorage.getItem("userEmail")
     let userUserPhone = localStorage.getItem("userPhone")
     let userUserPickUp = localStorage.getItem("userPickUp")
     let userUserDropPoint = localStorage.getItem("userDropPoint")
   
   
     const options: RazorpayOptions = {
       key: rKeyID,
       amount: location.state.totalAmt * 100,
       currency: "INR",
       name: "NATARAJ TRAVELS HOSPITALITY",
       description: "Payment for the seat booking for Mumbai Darshan",
       image: "https://dekhomumbai.com/preview2/static/media/logo2.7a31c2833049e07933ba.png",
   
       handler: (res) => {
         try {
           if (res) {
   
   
             saveUserData(res, userFirstName, userlastName, userUserEmail, userUserPhone, userUserPickUp, userUserDropPoint)
   
   
   
           }
   
   
   
           //console.log(res);
         } catch (error) {
           navigate("/payment-failed");
         }
       },
       prefill: {
         name: firstName,
         email: userEmail,
         contact: userPhone,
       },
   
       notes: {
         address: "St. George Hospital - Ground 01, Plot-235 Hussaini Building, DMello Road, Fort, Mumbai, Maharashtra 400001",
       },
       theme: {
         color: "#3399cc",
       },
     };
   
   
   
   
     const rzpay = new Razorpay(options);
     rzpay.open();
   }, [Razorpay]);*/


    const saveUserData = async (userOBJ) => {
      try {
     
        //add Bus data to collection called Bus Details
        const dbRef = doc(db, "user_bookings", userOBJ.userID);
        userDataArray = {busAc: location.state.dta.busAC, busArrival: location.state.dta.busArrival, busDeparture: location.state.dta.busDeparture, busDate: location.state.dta.busDate, busRoute: location.state.dta.busRoute, busFare: location.state.dta.busFare, busSeats: location.state.userSeats}
        setDoc(dbRef, userOBJ)
          .then(docRef => {
            console.log("A new bus details has been added to the Database!");
            navigate("/confirm-payment-details", { state: { custID: userOBJ.userID, userDB: userDataArray } });
          })
          .catch(error => {
            console.log(error);
          })
   
   
      } catch (e) {
        console.error("Error adding document: ", e);
      }
   
   
    }




  return (
    <>
      <br></br>
      <h2>Details and Payment Confirmation</h2>
      <div className='confirm-payment-details'>
        <h3>Dear User,</h3>
        <h5>Confirm your details and select your payment method to confirm your Mumbai Darshan tour.</h5>
        <br></br>
        <section id="bus-package-section">
          <h3>Bus information</h3>
          <div className='bus-package-container'>

            <div className='bus-package-info'>
              <ul style={{ listStyle: "none", display: "flex", gap: "18px", flexWrap: "wrap" }}>
                <li>
                  <label style={{ color: "black", fontSize: "18px", fontWeight: "bold" }}>Route: </label>
                  <b><span style={{ color: "#0895d3", fontSize: "18px" }}>{location.state.dta.busRoute}</span></b>
                </li>
                <li>
                  <label style={{ color: "black", fontSize: "18px", fontWeight: "bold" }}>Bus: </label>
                  <b><span style={{ color: "#0895d3", fontSize: "18px" }}>{location.state.dta.busAC === "Yes" ? "AC Bus" : "Non AC Bus"}</span></b>
                </li>
                <li>
                  <label style={{ color: "black", fontSize: "18px", fontWeight: "bold" }}>Date: </label>
                  <b><span style={{ color: "#0895d3", fontSize: "18px" }}>{result}</span></b>
                </li>
                <li>
                  <label style={{ color: "black", fontSize: "18px", fontWeight: "bold" }}>Fare: </label>
                  <b><span style={{ color: "#0895d3", fontSize: "18px" }}><b>₹ {location.state.dta.busFare}</b> </span></b>
                </li>
                <li>
                  <label style={{ color: "black", fontSize: "18px", fontWeight: "bold" }}>Seats selected: </label>
                  <b><span style={{ color: "#0895d3", fontSize: "18px" }}>{location.state.userSeats.join(", ")}</span></b>
                </li>
                <li>
                  <label style={{ color: "black", fontSize: "18px", fontWeight: "bold" }}>Arrival: </label>
                  <b><span style={{ color: "#0895d3", fontSize: "18px" }}>{location.state.dta.busArrival}</span></b>
                </li>
                <li>
                  <label style={{ color: "black", fontSize: "18px", fontWeight: "bold" }}>Departure: </label>
                  <b><span style={{ color: "#0895d3", fontSize: "18px" }}>{location.state.dta.busDeparture}</span></b>
                </li>
                <li>
                  <label style={{ color: "black", fontSize: "18px", fontWeight: "bold" }}>Total Amount: </label>
                  <b><span style={{ color: "#0895d3", fontSize: "18px" }}>₹ {location.state.totalAmt}</span></b>
                </li>
              </ul>

            </div>

            <div className='user-info-container'>
              <h4><b>User Information & Registration</b></h4>
              <form onSubmit={(e) => handlePayment(e)}>
                <div className='user-form'>
                  <div className="form-align">


                    <TextField variant="standard" className="txt-form"
                      label="First Name"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => {
                        console.log(e.target.value)
                        setFirstName(e.target.value);
                      }}
                      size="sm"

                    />
                    <TextField variant="standard" className="txt-form"
                      label="Last Name"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}

                    />



                  </div>
                  <div className="form-align">
                    <TextField variant="standard" style={{ width: "290px" }} className="txt-form"
                      label="Email"
                      placeholder="Email"
                      value={userEmail}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}

                    />


                  </div>
                  <div className="form-align">
                    <TextField variant="standard" style={{ width: "290px" }} className="txt-form"
                      label="Password"
                      placeholder="Password"
                      type="password"
                      value={userPassword}
                      onChange={(e) => {
                        setUserPassword(e.target.value);
                      }}

                    />


                  </div>

                  <div className="form-align">
                    <TextField variant="standard" className="txt-form"
                      label="Phone Number"
                      placeholder="Phone Number"
                      value={userPhone}
                      type="number"
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}

                    />
                  </div>
                  <div className="form-align">



                    <FormControl fullWidth>
                      <InputLabel id="PickUpPoint">Pick Up Point</InputLabel>
                      <Select
                        labelId="PickUpPoint"
                        id="PickUpPointselect"
                        value={userPickUp}
                        label="Pickup Point"
                        variant="standard"
                        onChange={(e) => {
                          setUserPickUp(e.target.value);
                        }}
                      >
                        {
                          pickDropData.map((place, keys) =>

                            <MenuItem value={place} id={keys}>{place}</MenuItem>
                          )

                        }


                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-align">
                    <FormControl fullWidth>
                      <InputLabel id="DropPoint">DropPoint</InputLabel>
                      <Select
                        labelId="DropPoint"
                        id="DropPointSelect"
                        value={userDropPoint}
                        label="Drop Point"
                        variant="standard"
                        onChange={(e) => {
                          setUserDropPoint(e.target.value);
                        }}
                      >
                        {
                          pickDropData.map((place, keys) =>

                            <MenuItem value={place} id={keys}>{place}</MenuItem>
                          )

                        }
                      </Select>
                    </FormControl>
                    {/*
                       <TextField variant="standard" className="txt-form"
                      label="Pick Up Point"
                      placeholder="Pick Up Point"
                      value={userPickUp}
                      type="text"
                      onChange={(e) => {
                        setUserPickUp(e.target.value);
                      }}

                    />
                  
                  */}



                    {/*
                    <TextField variant="standard" className="txt-form"
                      label="Drop Point"
                      placeholder="Drop Point"
                      value={userDropPoint}
                      type="text"
                      onChange={(e) => {
                        setUserDropPoint(e.target.value);
                      }}

                    />
                    
                    */}
                  </div>


                </div>
                <br></br>
                <Button type="submit" variant="outlined" style={{ border: "1px solid black", color: "black", fontWeight: "bold" }}>Proceed to Payment</Button>
              </form>
            </div>
            <br></br>


            <br></br>
          </div>
        </section>
      </div >
    </>
  )
}
