import React from 'react'
import "./BusRoute.css"
import bus from "../media/bus.png"
import bus2 from "../media/bus2.png"
import calender from "../media/date.png"
import { FaChair } from "react-icons/fa";

import { useState, useEffect } from "react";
import { db } from "../DB/Firebase.js";
import { collection, query, where, onSnapshot } from "firebase/firestore";

import { useNavigate, useLocation } from 'react-router-dom';


export default function BusRoute() {
  const navigate = useNavigate();

  const rlocation = useLocation();
  const bRoute = rlocation.state.bRoute;

  //Data
  const [rows, setRows] = useState([]);


  const getRoutes = () => {

    const busDataRef = collection(db, "bus_details");
    const q = query(busDataRef, where("busRoute", "==", bRoute));
    onSnapshot(q, (querySnapshot) => {
      const rows = [];
      querySnapshot.forEach((doc) => {
        rows.push(doc.data())
      });
      setRows(rows);
    });
  };


  const handleCustData = (busID) => {


    navigate("/customer-page", { state: { dta: busID } });

  }

  useEffect(() => {
    getRoutes();
  }, []);
  return (
    <>
      <div className='container-main'>
        <h3 className='tSpace h3-bg page-heading' style={{ marginTop: "0px" }}><img src={bus2} alt="bus" style={{ maxWidth: "50px", position: "absolute", marginLeft: "-52px", marginTop: "-10px" }} />Bus Details for Virar Route</h3>
        <br></br>
        <p >We follow the COVID-19 norms and all our buses are cleaned, sanitized after each trip. Time to time santize the seats.</p>

        {rows.map((row, index) => (
          <>
            <div className='widget-container' onClick={(e) => handleCustData(row)}>
              <div className='widget-details'>
                <div className='widget-grid-1'>
                  <img src={bus} alt="bus" className='widget-icon' />
                  <div className='widget-bus-info'>
                    <span><b>{row.busName}</b></span>
                    <span><small>{row.busAC === "Yes" ? "AC Bus" : "Non AC"} - {row.busCapacity} seats - {row.busRoute}</small></span>
                  </div>

                </div>
                <div className='widget-grid-2'>
                  <img src={calender} alt="calender" className='widget-icon' />
                  <div className='widget-bus-info-2'>
                    <span>Departure</span>
                    <span><small><b>{row.busDeparture}</b></small></span>

                  </div>

                </div>
                <div className='widget-grid-3'>
                  <img src={calender} alt="calender" className='widget-icon' />
                  <div className='widget-bus-info-3'>
                    <span>Arrival</span>
                    <span><small><b>{row.busArrival}</b></small></span>

                  </div>

                </div>
                <div className='widget-grid-4'>
                  <FaChair fontSize="28px" color="#145DA0" />
                  <div className='widget-bus-info-4'>

                    <span><small><b>{row.busCapacity}</b> seats available</small></span>

                  </div>

                </div>
              </div>

              <div className='tour-date'>
                <span>{(row.busDate)}</span>
                {/*<span>{currentDate + " " + currentMonth + " " + currentYear + " - " + currentDay}</span> */}
              </div>

              <div className='tour-fare'>
                <span className='tour-text-fare'>Fare</span><br></br><span className='tour-amount'><b>₹</b>{row.busFare}</span>
              </div>
            </div>
          </>
        ))}




      </div>

    </>
  )
}
