import React from 'react'
import { Routes, Route, Link, useNavigate } from "react-router-dom";

import contact from "../media/contact.jpg"

export default function Contact() {

    return (
        <>
         <div className="hero-home">
                <div className="hero-content-home">
                    <div className="hero-home-title shadoww">
                        Dekho Mumbai<br></br> is a joyful ride<br></br> in Mumbai.
                    </div>
                    <div className="hero-home-subTile shadoww">
                        Mumbai is all of dreams, passion and freedom.<br></br> Each moment in Mumbai is full of life and excitments,<br></br> lets explore Mumbai in most unique way.

                    </div>
                    <div className='learnmore-btn'><Link to="/">Explore</Link></div>

                </div>

            </div>
            <div className='container-main'>
                <h3 className='tSpace h3-bg page-heading' style={{ marginTop: "0px" }}><p style={{ fontSize: "20px", top: "-8px" }}>Contact us for information</p></h3>
                <br></br> <p >We follow the COVID-19 norms and all our buses are cleaned, sanitized after each trip. Time to time santize the seats.</p>

                <div className='mdContent-container'>
                    <img src={contact} alt="Contact" className='mdImg' />
                    <p className='mdContent-contact'>
                        <h3>Nataraj Travels Hospitality</h3>
                        St. George Hospital - Ground 01, Plot-235<br></br>
                        Hussaini Building, DMello Road, Fort,<br></br> Mumbai, Maharashtra 400001<br></br>
                        <span> <a href="mailto:info@natarajtravels.com">info@natarajtravels.com</a></span><br></br>
                        <span>+91 808 0429 020 | +91 908 2511 105</span>
                        <p><br></br></p>


                    </p>


                </div>
                <p className='report-bug'>In case you find any issues or bugs in the App, Do let us know :
                    <span><a href="mailto:report.errors@dekhomumbai.com">report.errors@dekhomumbai.com</a></span>
                </p>

            </div>

        </>
    )
}
